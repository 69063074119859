import React from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import * as s from './../setup/styles/globalStyles';

const Page = styled(s.Page)`
  position: relative;
  grid-template-rows: 1fr;
  align-items: center;
`;

const Artwork = styled.img`
  pointer-events: none;
  width: 100%;
`;

const BG = styled.div`
    opacity: 0.4;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -100;
    background: #000;
`;

const Distribution = () => {
  const { t } = useTranslation();

  return (
    <Page id="distribution">
      <s.Row>
        <s.Column>
          <s.Row>
            <s.Title2>
              {t("distribution.title")}
            </s.Title2>
          </s.Row>

          <s.BasicCard>
            {t("distribution.description-1")}
          </s.BasicCard>

          <s.BasicCard>
            {t("distribution.description-2")}
          </s.BasicCard>
        </s.Column>

        <s.Column>
          <Artwork src='/images/chart.png' />
        </s.Column>
      </s.Row>

      <BG />
    </Page>
  );
}

export default Distribution;

import React from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import * as s from './../setup/styles/globalStyles';

const Page = styled(s.Page)`
  grid-template-rows: 1fr 32px;
  position: relative;
  align-items: center;
`;

const Row = styled(s.Row)`
  justify-content: center;
  align-items: center;
`;

const Artwork = styled.img`
  pointer-events: none;
  width: 60%;
`;

const Footer = styled.div`
  font-size: 12px;
`;

const BG = styled.div`
    opacity: 0.1;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -100;
    background: #000;
`;

const Referral = () => {
  const { t } = useTranslation();

  return (
    <Page id="referral">
      <s.Row>
        <s.Column>
        <s.Row>
          <s.Title2>
            {t("referral.title")}
          </s.Title2>
          </s.Row>

          <s.BasicCard>
            {t("referral.description-1")}
          </s.BasicCard>
        </s.Column>

        <s.Column>
          <Artwork src='/images/referral.png' />
        </s.Column>
      </s.Row>

      <Row>
        <Footer>
          Copyright © 2022 Hazna
        </Footer>
      </Row>

      <BG />
    </Page>
  )
}

export default Referral;
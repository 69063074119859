import React from 'react';
import Home from './pages/Home';
import Platform from './pages/Platform';
import Token from './pages/Token';
import Distribution from './pages/Distribution';
import How from './pages/How';
import Referral from './pages/Referral';
import * as s from './setup/styles/globalStyles';

function App() {

  return (
    <s.Pages>
      <Home />
      <Platform />
      <Token />
      <Distribution />
      <How />
      <Referral />
    </s.Pages>
  );
}

export default App;

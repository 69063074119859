import styled from "styled-components";

export const Page = styled.section`
  display: grid;
  min-height: 100vh;
  padding: 2em 6em 2em 6em;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1920px;

  @media (max-width: 850px) {
    padding: 2em 2em 2em 2em;
  }
`;

export const Pages = styled.div`
  position: relative;
  width: 100%;
`;

export const Content = styled.div`
  padding: 2.5em 5.75em 1.5em 5.75em;
`;

export const Title = styled.div`
  font-size: 68px;
  font-weight: 600;
  color: var(--primary);
  text-align: justify;

  @media (max-width: 480px) {
    font-size: 48px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;

export const Title2 = styled.div`
  font-size: 78px;
  font-weight: 600;
  color: var(--accent-text);
  text-align: left;

  @media (max-width: 480px) {
    font-size: 32px;
  }

  @media (min-width: 1800px) {
    font-size: 78px;
  }
`;

export const Description = styled.div`
  opacity: 0.6;
  font-size: 20px;
  color: #000000;
  text-align: justify;
  line-height: 48px;
`

export const Header = styled.div`
  font-size: 28px;
  color: #000000;
  text-align: justify;
  font-weight: 600;
  margin-bottom: 4em;
`;

export const Text = styled.div`
  font-size: 20px;
  color: #000000;
`;

export const Image = styled.img`
  height: 88px;
  width: 88px;
`;

export const Message = styled.div`
  background: var(--accent);
  color: var(--accent-text);
  align-content: center;
  justify-content: center;
  margin-bottom: 40px;
  padding: 8px 12px;
  margin-left: 2em;
  font-weight: 600;
`;

export const Stack = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;

  @media (max-width: 1110px) {
    flex-direction: column;
  }
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 3em;

  @media (max-width: 850px) {
    display: none;
  }
`;

export const Footer = styled.footer`
  display: flex;
  font-weight: 400;
  justify-content: center;
  opacity: 0.4;
  font-size: 0.8em;
  font-weight: 300;
  padding-top: 8px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 850px) {
    flex-direction: column;
    gap: 3em;
  }
`;

export const Column = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  gap: 2em;
`;

export const BasicCard = styled.div`
  text-align: left;
  z-index: 100;
  border-radius: 10px;
  padding: 1em;
  font-size: 16px;
  line-height: 1.5;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  opacity: 0.8;

  @media (min-width: 1800px) {
    font-size: 24px;
  }
`;

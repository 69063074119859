import React from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import * as s from './../setup/styles/globalStyles';

const Page = styled(s.Page)`
  grid-template-rows: 1fr;
  position: relative;
  align-items: center;
`;

const Artwork = styled.img`
  pointer-events: none;
  width: 60%;
`;

const Blob = styled.img`
    opacity: 0.4;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -100;
    background: #000;
`;

const Platform = () => {
  const { t } = useTranslation();

  return (
    <Page id="platform">
      <s.Row>
        <s.Column>
          <s.Row>
            <s.Title2>
              {t("platform.title")}
            </s.Title2>
          </s.Row>

          <s.BasicCard>
            {t("platform.goal-1")}
          </s.BasicCard>

          <s.BasicCard>
            {t("platform.goal-1")}
          </s.BasicCard>

          <s.BasicCard>
            {t("platform.goal-1")}
          </s.BasicCard>
        </s.Column>

        <s.Column>
          <Artwork src='/images/platform.png' />
        </s.Column>
      </s.Row>

      <Blob src='/images/blob-3.svg' />
    </Page>
  )
}

export default Platform;

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ParallaxProvider } from 'react-scroll-parallax';
import './setup/index.css';
import './setup/i18n';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <ParallaxProvider>
        <App />
      </ParallaxProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

import React from 'react';
import { useTranslation } from "react-i18next";
import { useParallax } from 'react-scroll-parallax';
import styled from 'styled-components';
import Button from '../components/shared/Button';
import * as s from './../setup/styles/globalStyles';

const Page = styled(s.Page)`
  grid-template-rows: 96px 1fr;
  position: relative;
  
  align-items: center;
  padding: 1em 6em 2em 6em;

  @media (max-width: 850px) {
    padding: 2em 2em 2em 2em;
    grid-template-rows: 96px 1fr;
  }
  background: rgb(10,32,65);
  background: linear-gradient(151deg, rgba(10,32,65,1) 0%, rgba(25,92,194,1) 55%, rgba(10,32,65,1) 100%);
`;

const Row = styled(s.Row)`
  justify-content: center;
`;

const LColumn = styled(s.Column)`
  align-items: flex-start;
`;

const RColumn = styled(s.Column)`
  align-items: flex-end;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;

const Logo = styled.div`
  img {
    border-radius: 50%;
  }
`;

const Name = styled.div`
  font-size: 32px;
  margin-bottom: 0.1em;
  
`;

const Link = styled.a`
  text-decoration: none;
  color: #fff;
  opacity: 0.8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  font-size: 16px;
`
const Slogan = styled.div`
  font-size: 78px;
  font-weight: 600;
  z-index: 100;

  @media (max-width: 480px) {
    font-size: 32px;
  }
`;

const Description = styled.div`
  font-size: 18px;
  text-align: left;
  z-index: 100;
  opacity: 0.8;
  font-weight: 100;
  line-height: 1.5;
  text-align: justify;

  @media (min-width: 1800px) {
    font-size: 24px;
  }
`;

const Artwork = styled.img`
  pointer-events: none;
  width: 70%;
`;

const Home = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <Header />

      <s.Row>
        <s.Column>
          <Slogan>
            {t("home.slogan")}
          </Slogan>

          <Description>
            {t("home.description")}
          </Description>
        </s.Column>

        <s.Column>
          <Artwork src='/images/gem.png' />
        </s.Column>
      </s.Row>
    </Page>
  )
}

const Header = () => {
  const { t } = useTranslation();

  return (
    <s.Row>
      <LColumn>
        <LogoWrapper>
          <Logo>
            <img
              src={"/images/logo.png"}
              height={"64px"}
              width={"64px"}
            />
          </Logo>

          <Name>
            {"Hazna"}
          </Name>
        </LogoWrapper>
      </LColumn>

      <s.Column>
        <s.Navigation>
          <Link href="#platform">{t("home.navigation.link-1")}</Link>
          <Link href="#token">{t("home.navigation.link-2")}</Link>
          <Link href="#distribution">{t("home.navigation.link-3")}</Link>
          <Link href="#how">{t("home.navigation.link-4")}</Link>
          <Link href="#referral">{t("home.navigation.link-5")}</Link>
        </s.Navigation>
      </s.Column>
    </s.Row>
  )
}

export default Home;
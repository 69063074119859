import React from 'react';
import { useTranslation } from "react-i18next";
import { useParallax } from 'react-scroll-parallax';
import styled from 'styled-components';
import Button from '../components/shared/Button';
import * as s from './../setup/styles/globalStyles';

const Page = styled(s.Page)`
  grid-template-rows: 1fr;
  position: relative;
  align-items: center;
`;

const Artwork = styled.img`
  pointer-events: none;
  width: 60%;
`;

const BG = styled.div`
    opacity: 0.6;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -100;
    background: var(--primary);
`;

const How = () => {
  const { t } = useTranslation();

  return (
    <Page id="how">
      <s.Row>
        <s.Column>
          <s.Row>
            <s.Title2>
              {t("how.title")}
            </s.Title2>
          </s.Row>

          <s.BasicCard>
            {t("how.description-1")}
          </s.BasicCard>

          <s.BasicCard>
            {t("how.description-2")}
          </s.BasicCard>
        </s.Column>

        <s.Column>
          <Artwork src='/images/how.png' />
        </s.Column>
      </s.Row>

      <BG />
    </Page>
  )
}

export default How;